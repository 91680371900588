import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import MediaFilesUploader from 'components/MediaFilesUploader';
import Button from 'components/Button';
import MentionsInput from 'components/MentionsInput';
// redux
import { connect } from 'react-redux';
import { updateActionPlan, uploadAsset, deleteAsset, updateGeneralNotes } from 'reducers/siteVisitReport';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './AdditionalSection.module.scss';

const cn = classnames.bind(styles);

const svrQuestionMentionRoles = ['owner', 'caretaker', 'manager', 'vet'];

const AdditionalSection = ({
  uploadAsset,
  deleteAsset,
  report,
  updateGeneralNotes,
  updateActionPlan,
}, { router: { params } }) => {
  const [generalNotes, setGeneralNotes] = useState(report.general_notes);
  const [mentions, setMentions] = useState([]);
  const [actionPlan, setActionPlan] = useState(report.action_plan);

  const onMentionsChange = (mentions) => setMentions(mentions.map(({ id: user_id }) => ({ user_id })));

  const onGeneralNotesSave = () => {
    updateGeneralNotes(generalNotes, mentions);
  };

  const onActionPlanSave = () => {
    updateActionPlan(actionPlan, mentions);
  };

  const onFileRemove = (file) => deleteAsset(file);
  const onFileUploaded = (asset) => uploadAsset(asset);

  return (
    <div className={cn('general-section')}>
      {/* GENERAL NOTES */}
      {report.general_notes_section && (
        <div className={cn('notes-section')}>
          <div className={cn('title')}>
            <FormattedMessage id="general.generalNotes" />
          </div>
          <FormattedMessage id="general.enterNotesHere">
            {(placeholder) => (
              <MentionsInput
                comment={generalNotes}
                disabledIds={mentions.map((mention) => mention.user_id)}
                entityType="farm"
                entityId={params.farmId}
                inputType="square"
                mentionRoles={svrQuestionMentionRoles}
                onCommentChange={setGeneralNotes}
                onMentionsChange={onMentionsChange}
                placeholder={placeholder}
              />
            )}
          </FormattedMessage>
          <Button
            className={cn('save-btn')}
            onClick={onGeneralNotesSave}
            disabled={generalNotes === report.general_notes}
            primary
          >
            <FormattedMessage id="general.button.save" />
          </Button>
        </div>
      )}
      {/* ACTION PLAN */}
      {report.action_plan_section && (
        <div className={cn('notes-section')}>
          <div className={cn('title')}>
            <FormattedMessage id="general.actionPlan" />
          </div>
          <FormattedMessage id="general.enterActionPlanHere">
            {(placeholder) => (
              <MentionsInput
                comment={actionPlan}
                disabledIds={mentions.map((mention) => mention.user_id)}
                entityType="farm"
                entityId={params.farmId}
                inputType="square"
                mentionRoles={svrQuestionMentionRoles}
                onCommentChange={setActionPlan}
                onMentionsChange={onMentionsChange}
                placeholder={placeholder}
              />
            )}
          </FormattedMessage>
          <Button
            className={cn('save-btn')}
            onClick={onActionPlanSave}
            disabled={actionPlan === report.action_plan}
            primary
          >
            <FormattedMessage id="general.button.save" />
          </Button>
        </div>
      )}
      {report.photo_video_uploads && (
        <div className="mb-30">
          <div className={cn('title')}>
            <FormattedMessage id="general.media" />
          </div>
          <MediaFilesUploader
            onFileRemove={onFileRemove}
            onFileUploaded={onFileUploaded}
            uploadedFiles={report.svr_assets}
            uploadPath={`/svrs/${report.id}/chunk`}
          />
        </div>
      )}
    </div>
  );
};

AdditionalSection.propTypes = {
  report: T.object,
  uploadAsset: T.func.isRequired,
  deleteAsset: T.func.isRequired,
  updateActionPlan: T.func.isRequired,
  updateGeneralNotes: T.func.isRequired,
};

AdditionalSection.contextTypes = {
  router: T.object.isRequired,
};

export default connect((state) => ({
  report: state.siteVisitReport.report,
}), { uploadAsset, deleteAsset, updateGeneralNotes, updateActionPlan })(AdditionalSection);
