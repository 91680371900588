
export function trackHotJar() {
  const siteID = process.env.REACT_APP_HOTJAR_SITE_ID;
  if (!siteID) return;
  // eslint-disable-next-line func-names
  window.hj = window.hj || function () {
    // eslint-disable-next-line prefer-rest-params
    (window.hj.q = window.hj.q || []).push(arguments);
  };
  window._hjSettings = { hjid: siteID, hjsv: 6 };
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://static.hotjar.com/c/hotjar-' + window._hjSettings.hjid + '.js?sv=' + window._hjSettings.hjsv;
  document.getElementsByTagName('head')[0].appendChild(script);
}
