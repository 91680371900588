import { fetchFromAPI } from 'utils/api';

export const getTenantSettings = () => {
  return fetchFromAPI('/admin/tenant_settings')
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateTenantSettings = (data) => {
  return fetchFromAPI('/admin/tenant_settings', { method: 'put', body: JSON.stringify({ resource: data }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateCustomSurvey = (id, data) => {
  return fetchFromAPI('/admin/surveys', {
    method: 'put',
    params: { id },
    body: JSON.stringify({ resource: data })
  }).then((response) => response.resource);
};

export const removeCustomSurvey = (id) => {
  return fetchFromAPI('/admin/surveys', {
    method: 'delete',
    params: { id }
  });
};

export const addCustomSurvey = (data) => {
  return fetchFromAPI('/admin/surveys', {
    method: 'post',
    body: JSON.stringify({ resource: data })
  }).then((response) => { return response.resource; });
};

export const setTreatmentProtocolsResponsibleContact = (data) => {
  return fetchFromAPI('/admin/company_treatment_protocol_contacts', {
    method: 'post',
    body: JSON.stringify({ resource: data })
  }).then(({ resource }) => resource).catch((response) => { throw response; });
};

export const removeTreatmentProtocolsResponsibleContact = (id) => {
  return fetchFromAPI(`/admin/company_treatment_protocol_contacts/${id}`, { method: 'delete' })
    .catch((response) => { throw response; });
};

export const setExtendedInsightsResponsibleUser = (data) => {
  return fetchFromAPI('/admin/company_analytics_users', {
    method: 'post',
    body: JSON.stringify({ resource: data })
  }).then(({ resource }) => resource).catch((response) => { throw response; });
};

export const removeExtendedInsightsResponsibleUser = (id) => {
  return fetchFromAPI(`/admin/company_analytics_users/${id}`, { method: 'delete' })
    .catch((response) => { throw response; });
};

export const setReportsRestrictedUser = (data) => {
  return fetchFromAPI('/admin/company_svrs_disabled_users', {
    method: 'post',
    body: JSON.stringify({ resource: data })
  }).then(({ resource }) => resource).catch((response) => { throw response; });
};

export const removeReportsRestrictedUser = (id) => {
  return fetchFromAPI(`/admin/company_svrs_disabled_users/${id}`, { method: 'delete' })
    .catch((response) => { throw response; });
};
