import React, { useEffect, useRef, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import {
  fetchActivities,
  mergeNewActivities,
  clearNewActivities,
  markAsSeen,
  setPageHeight,
  setPageScrollTop,
  setActivitiesFilter,
} from 'reducers/farmfeed';
import { showSearch, hideSearch } from 'reducers/farmFeedSearch';
import { openPortal } from 'reducers/portal';
import { openPortalRight } from 'reducers/rightToLeftPortal';
import { setPageOptions } from 'reducers/layout';
// components
import FarmFeedUserFilter from 'components/FarmFeedUserFilter/FarmFeedUserFilter';
import FarmFeedActivities from 'components/FarmFeed/FarmFeedActivities';
import FarmFeedNewActivitiesBar from 'components/FarmFeed/FarmFeedNewActivitiesBar';
import FarmFeedNullState from 'components/FarmFeed/FarmFeedNullState';
import FarmFeedActivityPlaceholder from 'components/FarmFeed/FarmFeedActivityPlaceholder';
import Preloader from 'components/Preloader';
import UserAvatar from 'components/UserAvatar';
import UserFilterMobile from 'components/UserFilterMobile';
import FarmFeedPost from 'containers/Farmfeed/FarmFeedPost/FarmFeedPost';
import FarmFeedPostMobile from 'containers/Farmfeed/components/FarmFeedPostMobile';
import SearchFilters from './components/SearchFilters';
import { FormattedMessage } from 'react-intl';
import ReachEndPlaceholder from 'components/ReachEndPlaceholder/ReachEndPlaceholder';
import AllCaughtUp from './components/AllCaughtUp';
// hooks
import useDidMountEffect from 'hooks/useDidMountEffect';
import useAvailFarmFeedFilterSections from 'hooks/useAvailFarmFeedFilterSections';
import { useMediaQuery } from 'react-responsive';
// utils
import { Events, animateScroll, scrollSpy } from 'react-scroll';
import times from 'lodash.times';
import isEmpty from 'lodash.isempty';
import cn from 'classnames';
import isEqual from 'lodash.isequal';
import { isEmptyFarmFeedParamsSearch } from 'utils/farmFeedHelper';
import { userRoleKeys, isUserHasExactRoles } from 'utils/rolesHelper';
import { userFilterSectionTypes, userFilterGroupNames } from 'utils/constants/userFilters';
// other
import { newActivitiesDefaultSelector as newActivitiesSelector } from './selectors';

const FarmFeed = ({
  activities,
  bodyBagScrollTop,
  clearNewActivities,
  currentUser,
  fetchActivities,
  filter,
  hideSearch,
  isLoaded,
  isLoading,
  markAsSeen,
  mergeNewActivities,
  meta,
  newActivities,
  newActivitiesPadding,
  openPortal,
  openPortalRight,
  pageHeight,
  reqParams,
  setActivitiesFilter,
  setPageHeight,
  setPageScrollTop,
  setPageOptions,
  showSearch,
  unseenActivitiesCount,
}) => {
  const [isTypingMode, setIsTypingMode] = useState(false);
  const [shouldMergeActivities, setShouldMergeActivities] = useState(false);
  const ref = useRef({ farmFeedNode: null, filter, bodyBagNode: null });
  const availFilterSections = useAvailFarmFeedFilterSections();
  const mobileAvailFilterSections = availFilterSections.filter((f) => f.value !== userFilterSectionTypes.farmLocation);
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isEmptyUserFilter = isEmpty(filter);
  const isEmptyReqSearchParams = isEmptyFarmFeedParamsSearch(reqParams);
  const isNullState = !isLoading && !activities.length && isEmptyReqSearchParams && isEmptyUserFilter;
  const { roles_map: { admin }, farm_roles_count } = currentUser;
  const isUserEnableToPost = (admin || Boolean(farm_roles_count)) && isLoaded;
  const isUserCaregiverOnly = isUserHasExactRoles(currentUser, [userRoleKeys.caretaker]);
  const hasMoreItems = activities.length < meta.total;
  const isAllCaughtUp = !!activities.length && isEmptyReqSearchParams && isEmptyUserFilter &&
    ((unseenActivitiesCount + newActivities.length) === 0);

  const handleOpenMobileFilters = () => {
    openPortalRight(
      <UserFilterMobile
        allOrAnyConditionLabel={<FormattedMessage id="component.advancedSearchItem.allFilters" />}
        appliedFilter={filter}
        filterSections={mobileAvailFilterSections}
        filterGroupName={userFilterGroupNames.activities}
        setFilter={(filter) => setActivitiesFilter(filter || {})}
      />,
    );
  };

  const handleSetPageOptions = () => {
    if (isDesktop) {
      setPageOptions({ backLink: '', rightButton: '' });
      return;
    }
    setPageOptions({
      backLink: '',
      rightButton: {
        icon: 'fa fa-filter',
        onClick: handleOpenMobileFilters,
      },
    });
  };

  useEffect(() => {
    ref.current.bodyBagNode = document.getElementById('bodybag');

    if (!isLoaded) {
      clearNewActivities();
      fetchActivities();
    }
    const { farmFeedNode, bodyBagNode } = ref.current;

    if (pageHeight && isLoaded && farmFeedNode) {
      farmFeedNode.style.minHeight = pageHeight + 'px';
    }

    handleSetPageOptions();
    showSearch();
    Events.scrollEvent.register('end', () => setShouldMergeActivities(true));
    scrollSpy.update();

    if (bodyBagScrollTop && bodyBagNode) {
      setTimeout(() => {
        bodyBagNode.scrollTop = bodyBagScrollTop;
      }, 0);
    }

    return () => {
      const farmFeedNodeClientHeight = ref.current.farmFeedNode?.clientHeight;
      const bodyBagNodeScrollTop = ref.current.bodyBagNode?.scrollTop;
      if (farmFeedNodeClientHeight) setPageHeight(farmFeedNodeClientHeight);
      if (bodyBagNodeScrollTop) setPageScrollTop(bodyBagNodeScrollTop);
      hideSearch();
      setPageOptions({ rightButton: '' });
      Events.scrollEvent.remove('end');
    };
  }, []);

  useDidMountEffect(() => {
    const { farmFeedNode } = ref.current;
    const minHeight = farmFeedNode?.style.minHeight;
    if (farmFeedNode && minHeight) farmFeedNode.style.minHeight = null;
  }, [activities]);

  useEffect(() => {
    if (shouldMergeActivities) {
      mergeNewActivities(newActivities);
      setShouldMergeActivities(false);
    }
  }, [shouldMergeActivities]);

  useDidMountEffect(handleSetPageOptions, [isDesktop]);

  useDidMountEffect(() => {
    const { filter: prevFilter } = ref.current;
    if (isEmptyReqSearchParams && !isEqual(prevFilter, filter)) {
      fetchActivities();
      handleSetPageOptions();
    }
    ref.current.filter = filter;
  }, [filter]);

  const handleToggleTypingMode = () => {
    setIsTypingMode((prevIsTypingMode) => !prevIsTypingMode);
  };

  const handleLoadMoreActivities = () => {
    fetchActivities({ ...reqParams, page: reqParams.page + 1, padding: newActivitiesPadding }, true);
  };

  return (
    <div className="farmfeed-page" ref={(refElem) => { ref.current.farmFeedNode = refElem; }}>
      {isEmptyReqSearchParams && (
        <FarmFeedUserFilter
          currentFilter={isEmptyUserFilter ? undefined : filter}
          setActivitiesFilter={setActivitiesFilter}
          availFilterSections={availFilterSections}
          openMobileFilters={handleOpenMobileFilters}
          isMobileView={!isDesktop}
        />
      )}
      {!isEmptyReqSearchParams && (
        <SearchFilters
          farmFeedParams={reqParams}
          fetchData={fetchActivities}
          isMobileView={!isDesktop}
          openPortal={openPortal}
        />
      )}

      {isLoaded && (
        <FarmFeedNewActivitiesBar
          newActivities={newActivities}
          onClick={() => animateScroll.scrollToTop({ containerId: 'bodybag' })}
        />
      )}

      <div className="feed-container">
        <div onClick={handleToggleTypingMode} className={cn('overlay show-for-large', { 'visible': isTypingMode })} />

        {isUserEnableToPost && isEmptyReqSearchParams && isEmptyUserFilter && !isUserCaregiverOnly && (
          <>
            <FarmFeedPost
              className="show-for-large"
              toggleTypingMode={handleToggleTypingMode}
              isTypingMode={isTypingMode}
              currentUser={currentUser}
            />

            <div className="write-message-block hide-for-large" onClick={() => openPortal(<FarmFeedPostMobile />)}>
              <UserAvatar user={currentUser} size={40} className="mr-10" />
              <FormattedMessage id="container.farmfeed.writeSmth" />
            </div>
          </>
        )}

        {isAllCaughtUp && (
          <AllCaughtUp>
            <FormattedMessage id="container.farmfeed.caughtUpDesc" />
          </AllCaughtUp>
        )}

        <Preloader isActive={isLoading} />
        {isLoading && !isLoaded && (
          <div className="animated fadeIn">
            {times(3, (i) => <FarmFeedActivityPlaceholder key={i} />)}
          </div>
        )}

        {isNullState
          ? <FarmFeedNullState />
          : (
            <FarmFeedActivities
              activities={activities}
              loadMoreActivities={handleLoadMoreActivities}
              markAsSeen={markAsSeen}
              isLoading={isLoading}
              meta={meta}
              currentUser={currentUser}
            />
          )}
      </div>

      {!hasMoreItems && <ReachEndPlaceholder />}
    </div>
  );
};

FarmFeed.propTypes = {
  activities: T.array.isRequired,
  newActivities: T.array.isRequired,
  newActivitiesPadding: T.number,
  isLoading: T.bool.isRequired,
  isLoaded: T.bool.isRequired,
  fetchActivities: T.func.isRequired,
  mergeNewActivities: T.func.isRequired,
  showSearch: T.func.isRequired,
  hideSearch: T.func.isRequired,
  clearNewActivities: T.func.isRequired,
  setPageHeight: T.func.isRequired,
  setPageScrollTop: T.func.isRequired,
  pageHeight: T.number,
  bodyBagScrollTop: T.number,
  meta: T.object,
  reqParams: T.shape({
    page: T.number,
    filters: T.object.isRequired,
    search: T.string.isRequired,
    date_start: T.string,
    date_end: T.string,
  }),
  unseenActivitiesCount: T.number.isRequired,
  openPortal: T.func.isRequired,
  openPortalRight: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  markAsSeen: T.func.isRequired,
  filter: T.object.isRequired,
  setActivitiesFilter: T.func.isRequired,
  // from container
  currentUser: T.object.isRequired,
};

export default connect(
  (state, props) => ({
    activities: state.farmfeed.resources,
    newActivities: newActivitiesSelector(state, props),
    newActivitiesPadding: state.farmfeed.newActivities.padding,
    isLoading: state.farmfeed.isLoading,
    isLoaded: state.farmfeed.isLoaded,
    meta: state.farmfeed.meta,
    reqParams: state.farmfeed.params,
    pageHeight: state.farmfeed.height,
    bodyBagScrollTop: state.farmfeed.bodyBagScrollTop,
    filter: state.farmfeed.filter,
    unseenActivitiesCount: state.sidebar.counters.unviewedActivities,
  }), {
    setActivitiesFilter,
    fetchActivities,
    showSearch,
    hideSearch,
    mergeNewActivities,
    clearNewActivities,
    setPageHeight,
    setPageScrollTop,
    setPageOptions,
    openPortal,
    openPortalRight,
    markAsSeen,
  }
)(FarmFeed);
