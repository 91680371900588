import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import CenterBox from 'components/CenterBox';
import PigsUnderCareLine from 'components/PigsUnderCareLine';
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import ReactSelect from 'components/Select/ReactSelect';
import AttentionBox from 'components/AttentionBox/AttentionBox';
import OralTreatmentUnit from 'components/DailyCheckups/TreatmentProductLabels/OralTreatmentUnit';
import Translation from 'components/Translation';
import NumberStepInput from 'components/NumberStepInput/NumberStepInput';
import Preloader from 'components/Preloader';
import StickyFooter from 'components/StickyFooter';
import Button from 'components/Button';
import WithdrawalWarningBox from './components/WithdrawalWarningBox';
import PigsUnderTreatmentLine from 'components/PigsUnderTreatmentLine';
// api
import { getPigGroupDailyCheckupTreatmentProtocolProductStep } from 'endpoints/treatmentProtocols';
// redux
import { reportTreatments } from 'reducers/dailyCheckup/checkupEdit';
import { setPageOptions } from 'reducers/layout';
import { connect } from 'react-redux';
// utils
import { getCheckupBackUrls } from 'utils/checkupHelper';
import { isMobile } from 'react-device-detect';
import { getMedType, formatDosageType, withdrawalParams } from 'utils';
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './DailyCheckupReportProtocolTreatment.scss';
import moment from 'moment';

const parseDecimalText = (text, prev) => {
  const pattern = /^[+-]?\d+(\.\d+)?$/; // float number
  if (text === '') return text;
  if (!`0${text}0`.match(pattern)) return prev;
  return text;
};

const ExclamationTriangle = ({ isVisible }) => (
  <i className={cn('fa fa-exclamation-triangle-bts icon', { 'visible': isVisible })} />
);

ExclamationTriangle.propTypes = {
  isVisible: T.bool,
};

const DailyCheckupReportProtocolTreatment = ({
  checkup,
  setPageOptions,
  reportTreatments,
  measurementSystem,
  withdrawalTrackingOn,
}, { router }) => {
  const [treatment, setTreatment] = useState({ treatment_product: {}, skip_efficacy_survey: false });
  const { prevPageUrl, checkupUrl } = getCheckupBackUrls(checkup, router.location);
  const { treatment_product, quantity, head_count, gallons_value } = treatment;
  const {
    dosage_measure,
    dosage_type,
    notes,
    route,
    vaccine,
    quantity_step,
    name,
    id,
  } = treatment_product;

  useEffect(() => {
    setPageOptions({
      mobileTitle: 'reportTreatment',
      backLink: {
        link: prevPageUrl,
        icon: 'fa fa-times fs-18',
      }
    });

    return () => {
      setPageOptions({ mobileTitle: '' });
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(checkup) && isEmpty(treatment_product)) {
      const { pig_group_id } = checkup;
      const { params: { treatmentStepId } } = router;

      getPigGroupDailyCheckupTreatmentProtocolProductStep(pig_group_id, treatmentStepId)
        .then((stepData) => {
          const { treatment_protocol_product: { treatment_product }, id } = stepData;
          setTreatment((treatmentData) => ({
            ...treatmentData,
            treatment_product,
            treatment_protocol_product_step_id: id,
          }));
        })
        .catch((error) => {
          toastResponseErrors(error);
          router.push(prevPageUrl);
        });
    }
  }, [checkup]);

  useEffect(() => {
    if (treatment?.treatment_product?.vaccine || treatment?.treatment_product?.route?.includes('oral')) {
      const initialHeadCount =
        treatment?.treatment_product?.dosage_measure === 'head' && treatment?.treatment_product?.dosage_type === 'ml' ?
          1 :
          checkup?.pigs || 0;

      setTreatment((prevTreatment) => ({
        ...prevTreatment,
        head_count: initialHeadCount || 0
      }));
    }
  }, [treatment?.treatment_product]);

  const setTreatmentValue = (key, value) => {
    const oldValue = treatment[key];
    const newValue = ['quantity', 'gallons_value'].includes(key) ?
      parseDecimalText(value, treatment[key]) :
      parseInt(value, 10);

    if (newValue !== oldValue) {
      setTreatment((treatmentData) => ({
        ...treatmentData,
        [key]: parseFloat(newValue <= checkup.pigs ? newValue : checkup.pigs),
      }));
    }
  };

  const handleFocus = (e) => e.target.select();
  const handleChange = (key) => (value) => setTreatmentValue(key, value);
  const handleBlur = (key) => ({ target: { value } }) => setTreatmentValue(key, value);

  const handleSubmit = () => {

    const new_total_pigs_treated = Math.max(checkup.total_pigs_treated, treatment.head_count);
    let new_unsafe_pigs_treated = checkup.unsafe_pigs_treated;

    if (!treatment_product.safe) {
      new_unsafe_pigs_treated = Math.min(checkup.pigs, checkup.unsafe_pigs_treated + treatment.head_count);
    }

    const resource = {
      dc_treatments: [{ ...treatment, treatment_product_id: treatment_product.id }],
      total_pigs_treated: new_total_pigs_treated,
      unsafe_pigs_treated: new_unsafe_pigs_treated,
    };
    reportTreatments(checkup.id, { resource })
      .then(() => router.push(checkupUrl))
      .catch(toastResponseErrors);
  };

  const needWithdrawalWarningMessage = () => {
    const { value, period } = withdrawalParams(treatment_product);
    const isWithdrawalWarnActual = moment(checkup.created_on).add(value, period) >= moment();

    return withdrawalTrackingOn && isWithdrawalWarnActual;
  };

  const isContinueDisabled = () => {
    const dcEndDayPigsCount = checkup?.pigs || 0;
    const isHeadCountFilled = !!head_count;
    const isHeadCountValid = head_count <= dcEndDayPigsCount;
    const isGallonsValid = !((treatment_product.dosage_measure === 'gal') && !gallons_value);
    const isQuantityValid = !((getMedType(treatment_product) !== 'topical') && !parseFloat(quantity));

    return !isHeadCountFilled || !isHeadCountValid || !isGallonsValid || !isQuantityValid;
  };

  const getTreatmentLabel = () => {
    const dosageType = formatDosageType(dosage_type, measurementSystem);
    const type = <FormattedMessage id={`general.short.${dosageType}`} />;

    if (route.includes('oral')) {
      const msgKey = dosage_measure === 'head' ? 'oralLabel' : 'unitsAdministered';
      return (
        <FormattedMessage id={`container.checkupEdit.reportMedications.${msgKey}`} values={{ type }} />
      );
    }

    return (
      <FormattedMessage
        id="container.checkupEdit.reportMedications.injectableLabel"
        values={{ type, measure: <FormattedMessage id={`general.${dosage_measure}`} /> }}
      />
    );
  };

  const isDataLoaded = !isEmpty(checkup) && !isEmpty(treatment_product);

  if (!isDataLoaded) {
    return <Preloader isActive isCentered />;
  }

  let totalTreatedPigs =
    !treatment_product.safe ? checkup.treated_pigs + (treatment.head_count || 0) : checkup.treated_pigs;
  totalTreatedPigs = totalTreatedPigs > checkup.pigs ? checkup.pigs : totalTreatedPigs;

  return (
    <CenterBox className="DailyCheckupReportProtocolTreatment">
      <section className="center-box-header show-for-large">
        <div>
          <div className="subtitle">{checkup.pig_group_name}</div>
          <h1 className="title"><FormattedMessage id="general.pageTitle.reportTreatment" /></h1>
        </div>
        <Link to={prevPageUrl}><i className="fa fa-times close-center-box" /></Link>
      </section>
      <PigsUnderCareLine mobile pigsCount={checkup.pigs} />
      {checkup.abf_tracking && <PigsUnderTreatmentLine mobile pigsCount={totalTreatedPigs} />}
      <section className="center-box-body">
        {isMobile ? (
          <div className="mobile-treatment">
            <div className="treatment-name">{name}</div>
            <i className="change-treatment-icon fa fa-angle-right-btb" />
          </div>
        ) : (
          <ReactSelect
            placeholder={<FormattedMessage id="general.pageTitle.selectMedications" />}
            options={[treatment_product]}
            valueKey="id"
            labelKey="name"
            arrowRenderer={() => <i className="fa fa-angle-down" />}
            value={id}
            disabled
          />
        )}

        {needWithdrawalWarningMessage() &&
          <WithdrawalWarningBox treatmentProduct={treatment_product} checkup={checkup} />
        }

        {dosage_type === 'unit' && (
          <AttentionBox className="oral-attention">
            <FormattedMessage id="general.unitSize" />&nbsp;•&nbsp;
            <OralTreatmentUnit product={treatment_product} />
          </AttentionBox>
        )}

        {notes && (
          <AttentionBox showIcon={false} className="oral-attention">
            <span>
              <b><FormattedMessage id="general.notes" /> •&nbsp;</b>
              <Translation text={notes}>
                {notes}
              </Translation>
            </span>
          </AttentionBox>
        )}

        <div className="select-values-line">
          <div className="treatment-details">
            <div className="treatment-details-row">
              <i className="fa fa-exclamation-triangle-bts icon" />
              <NumberStepInput
                className="treatment-number-input"
                label={<FormattedMessage id="container.checkupEdit.reportMedications.headTreated" />}
                value={head_count || ''}
                onChange={handleChange('head_count')}
                onFocus={handleFocus}
                onBlur={handleBlur('head_count')}
              />
            </div>

            {!route.includes('topical') && (
              <div className="treatment-details-row">
                <ExclamationTriangle isVisible={vaccine && (quantity === 0)} />
                <NumberStepInput
                  label={getTreatmentLabel()}
                  value={quantity || (dosage_measure === 'head' && dosage_type === 'ml' ? 0.25 : 0)}
                  onChange={handleChange('quantity')}
                  onFocus={handleFocus}
                  onBlur={handleBlur('quantity')}
                  hasWarning={vaccine && (quantity === 0)}
                  type="number"
                  className="treatment-number-input"
                  step={quantity_step || 0.25}
                  normalizeValue={(value) => value.toString()}
                />
              </div>
            )}

            {dosage_measure === 'gal' && !route.includes('topical') && (
              <div className="treatment-details-row">
                <ExclamationTriangle isVisible={vaccine && (quantity === 0)} />
                <NumberStepInput
                  className="treatment-number-input"
                  label={<FormattedMessage id="general.gallonsWater" />}
                  value={gallons_value || ''}
                  onChange={handleChange('gallons_value')}
                  onFocus={handleFocus}
                  onBlur={handleBlur('gallons_value')}
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="center-box-footer show-for-large">
        <div className="buttons text-right">
          <Link className="button margin-right-1" to={prevPageUrl}>
            <FormattedMessage id="general.button.cancel" />
          </Link>
          <Button primary disabled={isContinueDisabled()} className="submit wider" onClick={handleSubmit}>
            <FormattedMessage id="general.button.continue" />
          </Button>
        </div>
      </section>
      <StickyFooter className="hide-for-large">
        <Button stickyFooter primary disabled={isContinueDisabled()} onClick={handleSubmit}>
          <FormattedMessage id="general.button.continue" />
        </Button>
      </StickyFooter>
    </CenterBox>
  );
};

DailyCheckupReportProtocolTreatment.contextTypes = {
  router: T.object.isRequired,
};

DailyCheckupReportProtocolTreatment.propTypes = {
  checkup: T.object,
  setPageOptions: T.func.isRequired,
  reportTreatments: T.func.isRequired,
  measurementSystem: T.string.isRequired,
  withdrawalTrackingOn: T.bool.isRequired,
};

export default connect((state) => ({
  measurementSystem: state.auth.user.current_company.measurement_system,
  withdrawalTrackingOn: state.auth.user.current_company.withdrawal_tracking_on,
}), { setPageOptions, reportTreatments })(DailyCheckupReportProtocolTreatment);
